import { useFragment } from '@apollo/client';
import { Button } from '@synoptic/ui-kit/button/button.js';
import { IconButton } from '@synoptic/ui-kit/button/icon-button.js';
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from '@synoptic/ui-kit/dropdown-menu/dropdown-menu.js';
import { LinkIcon } from '@synoptic/ui-kit/icons/react/link.js';
import { MoreHorizIcon } from '@synoptic/ui-kit/icons/react/more-horiz.js';
import { TrashIcon } from '@synoptic/ui-kit/icons/react/trash.js';
import { UserPlusIcon } from '@synoptic/ui-kit/icons/react/user-plus.js';
import { UserXmarkIcon } from '@synoptic/ui-kit/icons/react/user-xmark.js';
import { useToast } from '@synoptic/ui-kit/toast/toast-provider.js';
import { useMe } from '@/routes/_app/use-me.ts';
import { paths } from '@/routes-utils/paths.ts';
import { copyUrlToClipboard } from '@/utils/copy-url-to-clipboard.ts';
import {
  UserFollowingFragment,
  useFollow,
  useUnfollow,
} from '../../toggle-follow/use-toggle-follow.ts';
import { postCardActionsMenu } from '../post-card.css.ts';
import { useDelete } from '../use-delete.ts';
import {
  actionFollow,
  deleteConfirmationActions,
} from './post-card-actions-menu.css.ts';
import { PostCardAuthorFragmentFragment } from '@/__generated__/graphql.ts';
import { useState } from 'react';
import {
  ModalContent,
  ModalHeader,
  ModalRoot,
  ModalTitle,
  ModalTrigger,
} from '@synoptic/ui-kit/modal/modal.js';

export const PostCardActionsMenu = ({
  id,
  deletedAt,
  author,
}: {
  id: string;
  deletedAt?: string | null;
  author: PostCardAuthorFragmentFragment;
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const me = useMe();
  const [deletePost] = useDelete(id);
  const toast = useToast();

  const isDeleted = Boolean(deletedAt);

  const { data } = useFragment({
    fragment: UserFollowingFragment,
    from: {
      __typename: 'User',
      id: author.id,
    },
  });

  const [follow] = useFollow(author.id);
  const [unfollow] = useUnfollow(author.id);

  const isAuthor = author.id === me.id;

  const actions = [
    <DropdownMenuItem
      key="copy-link"
      icon={<LinkIcon />}
      onSelect={() => {
        copyUrlToClipboard(paths.makePost(id));
        toast.info({ title: 'Link copied to clipboard' });
      }}
    >
      Copy link to post
    </DropdownMenuItem>,
  ];

  if (!isAuthor) {
    const followAction = data.following ? (
      <DropdownMenuItem
        key="follow"
        icon={<UserXmarkIcon />}
        onSelect={() =>
          unfollow().then(() => {
            toast.info({
              title: `You unfollowed @${author.username}`,
            });
          })
        }
      >
        <span className={actionFollow}>Unfollow @{author.username}</span>
      </DropdownMenuItem>
    ) : (
      <DropdownMenuItem
        key="follow"
        icon={<UserPlusIcon />}
        onSelect={() => {
          follow().then(() => {
            toast.info({ title: `You followed @${author.username}` });
          });
        }}
      >
        <span className={actionFollow}>Follow @{author.username}</span>
      </DropdownMenuItem>
    );

    actions.push(followAction);
  }

  if (isAuthor && !isDeleted) {
    actions.push(
      <ModalTrigger key="delete" asChild>
        <DropdownMenuItem
          onSelect={() => setShowDeleteConfirmation(true)}
          icon={<TrashIcon />}
        >
          Delete
        </DropdownMenuItem>
      </ModalTrigger>,
    );
  }

  return (
    <div className={postCardActionsMenu} onClick={(e) => e.stopPropagation()}>
      <ModalRoot
        open={showDeleteConfirmation}
        onOpenChange={setShowDeleteConfirmation}
      >
        <DropdownMenuRoot>
          <DropdownMenuTrigger>
            <IconButton
              size="small"
              variant="tertiary"
              aria-label="Post actions menu"
            >
              <MoreHorizIcon style={{ height: 24, width: 24 }} />
            </IconButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent>{actions}</DropdownMenuContent>
        </DropdownMenuRoot>

        <ModalContent>
          <ModalHeader>
            <ModalTitle>Delete post?</ModalTitle>
          </ModalHeader>
          This can’t be undone. Content of your post will be lost but comments
          and votes can still be observed
          <div className={deleteConfirmationActions}>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteConfirmation(false)}
              size="small"
            >
              Cancel
            </Button>

            <Button
              startIcon={<TrashIcon />}
              onClick={() => {
                setShowDeleteConfirmation(false);
                deletePost().then(() => {
                  toast.info({ title: 'Your post was deleted' });
                });
              }}
              size="small"
              variant="critical"
            >
              Delete
            </Button>
          </div>
        </ModalContent>
      </ModalRoot>
    </div>
  );
};
